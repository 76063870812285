@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--rating-star: '#FCD91E';
}
body {
	margin: 0;
}
html,
body,
#root {
	width: 100%;
	height: 100%;
	/* overflow: hidden; */
	overscroll-behavior: none;
}
#app,
#app > div {
	height: 100%;
}

html {
	scrollbar-width: none !important;
	-ms-overflow-style: none;
}
html::-webkit-scrollbar {
	display: none;
}
.rating-star {
	gap: 1rem;
}
.rating-star .MuiRating-iconHover {
	color: var(--rating-star) !important;
}

.rating-star .MuiRating-iconFilled {
	color: var(--rating-star) !important;
}
.rating-star .MuiRating-iconEmpty {
	color: #d9d9d9 !important;
}
.MuiRating-icon {
	color: black !important;
}
.MuiCard-root {
	overflow: visible !important;
}
.MuiDrawer-paper {
	z-index: 990 !important;
}
.test-class {
	font-family: Poppins;
	font-size: 60px;
	font-weight: 500;
	line-height: 90px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}
.bruh {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}
.text-response {
	font-family: Poppins !important;
	font-size: 10px !important;
	font-weight: 400 !important;
	line-height: 15px !important;
}
.multiple-choice > MuiSvgIcon-fontSizeMedium {
	font-size: 24px;
}
.no-scrollbar::-webkit-scrollbar {
	display: none;
}
